import api from "api"
import useSWR from "swr"

/**
 * 複数コンタクト情報を取得するSWR
 */
export const useCustomers = (ownerId?: string) => {
  const keys = ownerId ? ["/users/customers", ownerId] : null
  return useSWR<Customer[]>(keys, (path, ownerId) => api.get(path, { params: { owner_id: ownerId } }).then(res => res.data), {
    revalidateOnFocus: false
  })
}
