import api from "api"
import useSWR from "swr"

/**
 * カード情報を取得するSWR
 */
export const useUsersCards = () => {
  return useSWR<Card[]>("/users/cards", (key) => api.get(key).then(res => res.data), {
    revalidateOnFocus: false
  })
}
