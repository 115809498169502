import { Select, Typography } from "antd"
import colors from "helpers/colors"
import Image from "next/image"
import Link from "next/link"
import router from "next/router"
import { useTranslation } from "next-i18next"
import MenuFilledIcon from "public/icons/menuFilled.svg"
import SearchIcon from "public/icons/search.svg"
import { useState, VFC } from "react"
import styled from "styled-components"

import { Drawer } from "./Drawer"

type Props = {
  onlyLogo?: boolean
}

export const HeaderForSp: VFC<Props> = ({ onlyLogo }) => {
  const [isSearchMode, setIsSearchMode] = useState(false)
  const [isOpenDrawer, setIsOpenDrawer] = useState(false)
  const [searchText, setSearchText] = useState<string>()
  const { t } = useTranslation()

  if (onlyLogo) {
    return (
      <div style={{
        background: "white",
        display: "flex",
        height: 60,
        padding: "16px",
        borderBottom: `1px solid ${colors.border}`,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        gridGap: "16px",
      }}>
        <Image src={"/logo.png"} height={40} width={61} objectFit="contain" />
      </div>
    )
  }

  return (
    <>
      <div style={{
        background: "white",
        display: "flex",
        height: 60,
        padding: "16px",
        borderBottom: `1px solid ${colors.border}`,
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
        gridGap: "16px",
        position: "sticky",
        top: 0,
        zIndex: 100,
      }}>
        <a onClick={() => setIsOpenDrawer(true)} style={{ height: "24px" }}>
          <MenuFilledIcon style={{ fontSize: "20px", fill: "black" }} />
        </a>
        {isSearchMode ? (
          <StyledInputSearch
            showSearch
            value={searchText !== "" ? searchText : undefined}
            onChange={() => router.push(`/search?q=${searchText}`)}
            onSearch={(value) => setSearchText(value)}
            options={searchText ? [{ label: t("{{searchText}}で検索する", { searchText }), value: "search" }] : undefined}
            notFoundContent={null}
            filterOption={false}
            suffixIcon={null}
            bordered={false}
            placeholder={
              <Typography.Text style={{ color: colors.grey30, marginRight: "8px", fontSize: "14px" }}>
                {t("検索キーワード")}
              </Typography.Text>
            }
          />
        ) : (
          <Link href="/">
            <a style={{ height: 25.6 }}>
              <Image src={"/logo.png"} height={25.6} width={64} objectFit="contain"/>
            </a>
          </Link>
        )}
        <a onClick={() => setIsSearchMode((prev) => !prev)} style={{ height: 24, width: 24 }}>
          <SearchIcon style={{ fontSize: "24px", fill: "black" }} />
        </a>
      </div>
      <Drawer isOpenDrawer={isOpenDrawer} onClose={() => setIsOpenDrawer(false)} />
    </>
  )
}

const StyledInputSearch = styled(Select)`
  flex: 1;
  height: 40px;
  border-radius: 3px;
  background-color: #f0f0f0;

  animation: blinking 0.3s ease-in-out alternate;

  @keyframes blinking {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

