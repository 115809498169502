import api from "api"
import useSWR from "swr"

/**
 * カード情報を取得するSWR
 */
export const useCard = (id?: string) => {
  return useSWR<Card>(id ? `/orders/${id}/card` : null, (key) => api.get(key).then(res => res.data), {
    revalidateOnFocus: false
  })
}
