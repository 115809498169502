import { message } from "antd"
import api from "api"
import constants from "helpers/constants"
import { urls } from "helpers/urls"
import Cookies from "js-cookie"
import Link from "next/link"
import { TFunction } from "next-i18next"

const logout = async (t: TFunction) => {
  const { error }: { error: any } = await api.delete("/sign_out")
  if (error) {
    console.log(error)
  } else {
    Cookies.remove(constants.cookieName)
    message.success(t("ログアウトしました"))
    if (window.location.pathname.includes(urls.account())) window.location.replace("/")
    else window.location.reload()
  }
}

export const getAccountMenuItems = (t: TFunction) => [
  {
    key: "profile",
    label: (
      <Link href={urls.account("profile")}>
        <a>{t("プロフィール編集")}</a>
      </Link>
    )
  },
  {
    key: "edit",
    label: (
      <Link href={urls.account("edit")}>
        <a>{t("アカウント管理")}</a>
      </Link>
    )
  },
  {
    key: "orders",
    label: (
      <Link href={urls.account("orders")}>
        <a>{t("注文履歴")}</a>
      </Link>
    )
  },
  {
    key: "lotteries",
    label: (
      <Link href={urls.account("lotteries")}>
        <a>{t("エントリー履歴")}</a>
      </Link>
    )
  },
  {
    key: "follows",
    label: (
      <Link href={urls.account("follows")}>
        <a>{t("お気に入り")}</a>
      </Link>
    )
  },
  {
    key: "event_checkins",
    label: (
      <Link href={urls.account("event_checkins")}>
        <a>{t("イベント訪問履歴")}</a>
      </Link>
    )
  },
  {
    key: "shipping_addresses",
    label: (
      <Link href={urls.account("shipping_addresses")}>
        <a>{t("お届け先")}</a>
      </Link>
    )
  },
  {
    key: "payments",
    label: (
      <Link href={urls.account("payments")}>
        <a>{t("お支払い方法")}</a>
      </Link>
    )
  },
  {
    key: "logout",
    label: (
      <a onClick={() => logout(t)}>
        <span>{t("ログアウト")}</span>
      </a>
    )
  }
]

