import styled from "styled-components"

export const GlobalNoScrollbarWrapper = styled.div`
  & * {
    &::-webkit-scrollbar {
      display: none;
      -webkit-appearance: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`
