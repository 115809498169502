import api from "api"
import useSWR from "swr"

/**
 * お届け先情報を取得するSWR
 */
export const useOrderShippingAddress = (orderId?: string) => {
  return useSWR<ShippingAddress>(orderId ? `/orders/${orderId}/shipping_address` : null, (key) => api.get(key).then(res => res.data), {
    revalidateOnFocus: false
  })
}
