import api from "api"
import useSWR from "swr"

/**
 * カード情報を取得するSWR
 */
export const useUnReadMessageCount = () => {
  return useSWR<number>("/users/un_read_message_count", (key) => api.get(key).then(res => res.data), {
    revalidateOnFocus: false
  })
}
