import { Typography } from "antd"
import Link from "next/link"
import ArrowRightIcon from "public/icons/arrow/next.svg"
import { Fragment, VFC } from "react"
import styled from "styled-components"

type Props = {
  breadcrumbs: Array<{
    text: string
    href?: string
  }>
  xs?: boolean
}

export const Breadcrumbs: VFC<Props> = ({
  breadcrumbs, xs
}) => {
  return (
    <div style={{ position: "relative" }}>
      <CoverDiv />
      <Fade />
      <div style={{
        padding: xs ? "20px 16px" : "20px 64px",
        zIndex: 2,
        position: "relative",
        overflow: "scroll"
      }}>
        <Typography.Text style={{ whiteSpace: "nowrap", lineHeight: 1, display: "flex", alignItems: "center", gap: "4px", paddingLeft: "4px" }}>
          {breadcrumbs.map((breadcrumb, index) => {
            return (
              <Fragment key={index}>
                {index > 0 && (<ArrowRightIcon style={{ height: "10px", width: "10px", minHeight: "10px", minWidth: "10px" }} />)}
                {breadcrumb.href ? (
                  <Link href={breadcrumb.href}>
                    <a style={{ textDecoration: "underline" }}>
                      <Typography.Text style={{ fontSize: 14, fontWeight: index === (breadcrumbs.length - 1) ? 700 : "normal" }}>
                        {breadcrumb.text}
                      </Typography.Text>
                    </a>
                  </Link>
                ) : (
                  <Typography.Text style={{ fontSize: 14, fontWeight: index === (breadcrumbs.length - 1) ? 700 : "normal" }}>{breadcrumb.text}</Typography.Text>
                )}
              </Fragment>
            )
          })}
        </Typography.Text>
      </div>
    </div>
  )
}

const CoverDiv = styled.div`
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index:1;
  background-color: #F5F5F5;
  width: 100vw;
  margin: 0 calc(50% - 50vw);
`

const Fade = styled.div`
  position: absolute;
  right: 0;
  z-index:3;
  background: linear-gradient(270deg, #F5F5F5 27.08%, rgba(245, 245, 245, 0.00) 100%);
  width: 82px;
  height: 52px;
`
