// eslint-disable-next-line no-restricted-imports
import { Avatar as OriginalAvatar } from "antd"
import AnonymousIcon from "public/icons/anonymous.svg"
import { ComponentProps, VFC } from "react"

type Props = { isFallBackAnonymous: boolean } & ComponentProps<typeof OriginalAvatar>

export const Avatar:VFC<Props> = (props) => {
  return (
    <OriginalAvatar
      {...props}
      style={{ display: "flex", flex: "none" }}
      // NOTE: fallback用にアイコンを設定している
      icon={props.isFallBackAnonymous ? (
        <AnonymousIcon
          width="100%"
          height="100%"
        />
      ) : undefined}
    />
  )

}
