import { Drawer as AntDesignDrawer, Dropdown, Menu, Typography } from "antd"
import { useAuth } from "contexts/auth"
import { urls } from "helpers/urls"
import { useSwitchLanguage } from "hooks/shared/useSwitchLanguage"
import Link from "next/link"
import { useRouter } from "next/router"
import { useTranslation } from "next-i18next"
import ArrowDownIcon from "public/icons/arrow/down.svg"
import WorldIcon from "public/icons/world.svg"
import { useMemo, VFC } from "react"
import styled from "styled-components"

import { Button } from "../Button"

type Props = {
  isOpenDrawer: boolean
  onClose: ()=> void
}

export const Drawer:VFC<Props> = ({ isOpenDrawer, onClose }) => {
  const { isAuthenticated, setIsSignUpOpened, setIsSignInOpened } = useAuth()
  const { t, i18n } = useTranslation()
  const router = useRouter()
  const { switchLanguage } = useSwitchLanguage()

  const menuItems = useMemo(() => {
    const ja = i18n.language === "ja"
    return ([
      {
        link: urls.events,
        text: t("イベント")
      },
      {
        link: urls.art_works,
        // FIXME: 作品のenがArtWorkになっているのでハードコーディングしている
        // 翻訳ファイルの分割方針を決めてファイルを分けた後に修正する
        text: ja ? t("作品") : t("Artworks")
      },
      {
        link: urls.owners,
        // FIXME: 作品のenがGalleryになっているのでハードコーディングしている
        // 翻訳ファイルの分割方針を決めてファイルを分けた後に修正する
        text: ja ? t("ギャラリー") : t("Galleries")
      },
      {
        link: urls.magazines,
        text: t("マガジン")
      },
      {
        link: urls.auction,
        text: t("オークション")
      },
    ])
  }, [i18n.language, t])

  const handleSignUp = () => {
    onClose()
    setIsSignUpOpened(true)
  }

  const handleSignIn = () => {
    onClose()
    setIsSignInOpened(true)
  }

  return (
    <AntDesignDrawer
      headerStyle={{
        border: "none",
        padding: 0,
        height: 0
      }}
      title={null}
      placement="left"
      closeIcon={null}
      onClose={onClose}
      visible={isOpenDrawer}
      bodyStyle={{ padding: "0px", display: "flex", flexDirection: "column" }}
      contentWrapperStyle={{ width: "80%" }}
    >
      <div style={{ display: "flex", flexDirection: "column", padding: "8px 16px" }}>
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item onClick={() => switchLanguage("ja")}>
                {t("JP")}
              </Menu.Item>
              <Menu.Item onClick={() => switchLanguage("en")}>
                {t("EN")}
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <a onClick={(e) => e.preventDefault()}>
            <div style={{ display: "flex", gap: 8, alignItems: "center", height: 34 }}>
              <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
                <WorldIcon style={{ width: 16, height: 16 }} />
                <Typography.Text style={{ fontSize: 14, lineHeight: 1 }}>
                  {router.locale === "ja" ? t("JP") : t("EN")}
                </Typography.Text>
              </div>
              <ArrowDownIcon height={8} width={8} />
            </div>
          </a>
        </Dropdown>
      </div>
      <div style={{ display: "flex", flexDirection: "column", marginBottom: "24px", marginTop: "16px" }}>
        {menuItems.map((menuItem, index) => {
          return (
            <Link href={menuItem?.link || "/"} key={index}>
              <a>
                <StyledDiv style={{ height: "45px", padding: "0px 20px", display: "flex", alignItems: "center" }} onClick={onClose}>
                  <Typography.Text style={{ fontSize: "14px" }}>
                    {t(menuItem?.text)}
                  </Typography.Text>
                </StyledDiv>
              </a>
            </Link>
          )
        })}
      </div>
      {!isAuthenticated && (
        <div style={{ padding: "24px 16px", display: "flex", flexDirection: "column", gap: "16px", borderTop: "1px solid #C4C4C4", borderBottom: "1px solid #C4C4C4" }}>
          <Button type={"primary"} onClick={handleSignUp}>
            {t("新規会員登録")}
          </Button>
          <Button type={"secondary"} onClick={handleSignIn}>
            {t("ログイン")}
          </Button>
        </div>
      )}
    </AntDesignDrawer>
  )
}

const StyledDiv = styled.div`
:hover{
  background-color: #F5F5F5;
}

`
